@import '../node_modules/bootstrap/dist/css/bootstrap.css';

@font-face {
  font-family: 'Rough-Typewriter';
  src: url('./assets/fonts/rough_typewriter.otf') format('truetype');
  src: url('./assets/fonts/rough_typewriter-bld-itl.otf') format('truetype');
}
@font-face {
  font-family: 'Antro-Vectra';
  src: url('./assets/fonts/Antro_Vectra.otf') format('truetype');
  src: url('./assets/fonts/Antro_Vectra_Bolder.otf') format('truetype');
}
@font-face {
  font-family: 'Madeleina-Sans';
  src: url('./assets/fonts/Madeleina\ Sans.otf') format('truetype');
}

html,
body {
  padding: 0;
  margin: 0;
  background-color: #fff8f2;
}

/* Custom Classes */

.coming-soon {
  height: 100vh;
}

/* Fonts */

p {
  font-family: 'Rough-Typewriter', sans-serif;
  color: #f73b18;
}

/* Images */
.main-logo {
  height: 700px;
}

@media only screen and (max-width: 700px) {
  .main-logo {
    height: 350px;
  }
}
